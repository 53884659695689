import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  if (user && user.emailVerified) {
    return children;
  } else {
    return <Navigate replace to="/hola" />;
  }
};

export default PrivateRoute;
