import React, { createContext, useContext, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/FirebaseConfig";
import { useAuth } from "./AuthContext";

const UserDataContext = createContext();

export const UserDataProvider = ({ children }) => {
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);

  const fetchUserData = async () => {
    try {
      const docRef = doc(db, "usuarios", user.uid);
      const userDoc = await getDoc(docRef);
      const loadedData = userDoc.data();
      setUserData(loadedData);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <UserDataContext.Provider value={{ userData, setUserData, fetchUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => {
  return useContext(UserDataContext);
};
