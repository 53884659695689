import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useReloadContext } from "../context/ReloadContext.js";
import { auth, db, storage } from "../firebase/FirebaseConfig";
import handleFileChange from "../functions/HandleFileChange.js";
import ProfileSkelleton from "../skelletons/ProfileSkelleton";
import ErrorAlertSmall from "./ErrorAlertSmall";
import Subtext from "./Subtext";

const EditProfileForm = ({ userData }) => {
  const user = auth.currentUser;
  const { setReload, defaultAvatar } = useReloadContext();
  const [loading, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const updatePreviewImage = () => {
      if (userData) {
        setPreviewImage(userData.foto);
      } else {
        setPreviewImage("");
      }
    };

    updatePreviewImage();
  }, [userData]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const updateNameAndPic = async (data) => {
    try {
      const name = `${data.firstName} ${data.lastName}`;
      let photoURL = "";
      const docRef = doc(db, "usuarios", user.uid);
      if (selectedFile) {
        const imageRef = ref(storage, `userPhoto/${user.uid}/profilePic.jpeg`);
        await uploadBytes(imageRef, selectedFile);
        const downloadedURL = await getDownloadURL(imageRef);
        photoURL = downloadedURL;
      } else {
        photoURL = previewImage;
      }
      await updateDoc(docRef, {
        nombre: name,
        foto: photoURL,
        nuevoUsuario: false,
      });
      setReload(true);
      navigate("/", { replace: true });
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <ProfileSkelleton />
      ) : (
        <form
          onSubmit={handleSubmit((data) => {
            setLoading(true);
            updateNameAndPic(data);
            reset();
          })}
          className="mt-12 flex flex-col items-center justify-evenly gap-8 md:flex-row md:items-start md:py-8"
        >
          {/* avatar div  */}
          <div className="flex flex-col items-center gap-4">
            <label id="profilePicLabel" htmlFor="profilePic" className="cursor-pointer">
              <div className="avatar">
                <div className="w-52 rounded-full ring ring-primary ring-offset-2 ring-offset-base-100">
                  <img
                    src={previewImage ? previewImage : defaultAvatar}
                    alt="avatar"
                    type="file"
                  />
                </div>
              </div>
              <input
                id="profilePic"
                type="file"
                className="hidden"
                onChange={(e) =>
                  handleFileChange(e, setPreviewImage, setSelectedFile)
                }
              />
            </label>
            <Subtext>
              Haz click en la imágen para cambiar tu foto de perfil
            </Subtext>
          </div>

          {/* name div  */}
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <Subtext>Nombre(s):</Subtext>
              <input
                id="name"
                className="input input-primary"
                type="text"
                {...register("firstName", {
                  required: "Este campo no debe estar vacío",
                })}
              />
              {errors.firstName && (
                <ErrorAlertSmall>{errors.firstName.message}</ErrorAlertSmall>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <Subtext>Apellido(s)</Subtext>
              <input
                id="lastName"
                className="input input-primary"
                type="text"
                {...register("lastName", {
                  required: "Este campo no debe estar vacío",
                })}
              />
              {errors.lastName && (
                <ErrorAlertSmall>{errors.lastName.message}</ErrorAlertSmall>
              )}
            </div>
            <button type="submit" className="btn btn-secondary">
              Guardar
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default EditProfileForm;
