import React from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider } from "./context/AuthContext";
import { ReloadProvider } from "./context/ReloadContext";
import "./index.css";
import RoutesMap from "./routes/RoutesMap";
import { UserDataProvider } from "./context/UserDataContext";

const Index = () => (
  <AuthProvider>
    <UserDataProvider>
      <ReloadProvider>
        <RoutesMap />
      </ReloadProvider>
    </UserDataProvider>
  </AuthProvider>
);

const root = createRoot(document.getElementById("root"));
root.render(<Index />);
