import React, { useState } from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import NavSidebaritems from "../elements/NavSidebarItems";

const NavToolBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);


  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const hanldeOption = (option) => {
    setDrawerOpen(false);
  };

  return (
    <div className="z-50 flex h-[100vh] flex-col py-24">
      <div className="drawer ">
        <input
          id="my-drawer"
          type="checkbox"
          className="drawer-toggle"
          checked={drawerOpen}
          onChange={() => ""}
        />
        <div className="drawer-content relative md:w-20 px-1 md:px-2">
          {/* Page content here */}
          <div className="fixed">
          <label
            htmlFor="my-drawer"
            className="btn fixed -left-4 top-24 bg-violet-200/70 md:drawer-button md:static md:bg-violet-200"
            onClick={handleToggleDrawer}
          >
            <AiOutlineMenuUnfold className="text-violet-700" size={20} />
          </label>
          </div>
        </div>
        <div className="drawer-side">
          <label
            htmlFor="my-drawer"
            aria-label="close sidebar"
            className="drawer-overlay"
            onClick={()=> setDrawerOpen(false)}
          ></label>
          <ul className="menu min-h-full w-80 bg-white p-4 py-24 text-base-content">
            <NavSidebaritems closeDrawer={hanldeOption} />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavToolBar;
