import React from "react";
import Dashboard from "./components/Dashboard";
import Navbar from "./components/Navbar";

const App = () => {
  return (
    <>
      <Navbar />
      <Dashboard />
    </>
  );
};

export default App;
