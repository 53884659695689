import { lazy, Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "../App";
import LandingPage from "../components/LandingPage";
import NotFoundPage from "../components/NotFoundPage";
import Profile from "../components/Profile";
import PrivateRoute from "./PrivateRoutes";

const HomeDashboard = lazy(() => import("../components/HomeDashboard"));
const ColdBar = lazy(() => import("../components/pages/ColdBar"));
const Hotbar = lazy(() => import("../components/pages/Hotbar"));
const Entries = lazy(() => import("../components/pages/Entries"));
const Beverages = lazy(() => import("../components/pages/Beverages"));
const Menu = lazy(() => import("../components/pages/Menu"));

const router = createBrowserRouter([
  {
    path: "/hola",
    element: <LandingPage />,
  },
  {
    path: "/",
    element: (
      <PrivateRoute>
        <App />
      </PrivateRoute>
    ),
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <HomeDashboard />
          </Suspense>
        ),
      },
      {
        path: "/barrafria",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ColdBar />
          </Suspense>
        ),
      },
      {
        path: "/barrafria",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ColdBar />
          </Suspense>
        ),
      },
      {
        path: "/barracaliente",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Hotbar />
          </Suspense>
        ),
      },
      {
        path: "/entradas",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Entries />
          </Suspense>
        ),
      },
      {
        path: "/bebidas",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Beverages />
          </Suspense>
        ),
      },
      {
        path: "/menu",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Menu />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/perfil",
    element: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
  },
  {
    path: "/error",
    element: <NotFoundPage />,
  },
]);

const RoutesMap = () => {
  return <RouterProvider router={router} />;
};

export default RoutesMap;
