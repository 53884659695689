import React, { useState, useEffect } from "react";
import EditProfileForm from "../elements/EditProfileForm";
import ProfileSkelleton from "../skelletons/ProfileSkelleton";
import Navbar from "./Navbar";
import { useUserData } from "../context/UserDataContext";

const Profile = () => {
  const [loadProfile, setLoadProfile] = useState(true);
  const { fetchUserData, userData } = useUserData();

  useEffect(() => {
    fetchUserData();
    if (userData) setLoadProfile(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Navbar />
      <div className="mt-20 flex h-[100vh] w-full flex-col gap-8 bg-blue-100 p-4">
        {loadProfile ? (
          <div className="flex flex-col gap-2 rounded bg-white p-8 shadow-2xl">
            <ProfileSkelleton />
          </div>
        ) : (
          <div className="flex flex-col gap-2 rounded bg-white p-8 shadow-2xl">
            <EditProfileForm userData={userData} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
