import React from "react";
import { TbGhostFilled } from "react-icons/tb";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="flex h-[100vh] w-full select-none flex-col items-center justify-center gap-4 bg-gray-900/70 p-8 text-white">
      <Link to="/">
        <TbGhostFilled size={150} />
      </Link>
      <Link to="/">
        Error 404: La página que buscas no existe, da click aqui para volver
      </Link>
    </div>
  );
};

export default NotFoundPage;
