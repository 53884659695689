import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import LandingRenderer from "../elements/LandingRenderer";

const LandingPage = () => {
  const [login, setLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user && user.emailVerified) navigate("/");
  }, [user, navigate]);

  return (
    <div className="h-[100vh] flex justify-center bg-blue-100">
      <div className="flex mt-8 flex-col justify-center gap-8  p-4">
        <div className="flex select-none justify-center text-white ">
          <div className="flex w-fit flex-col gap-8 rounded-lg bg-gradient-to-tr from-violet-700 to-blue-500 p-4 shadow-lg">
            <LandingRenderer
              login={login}
              loading={loading}
              setLogin={setLogin}
              setLoading={setLoading}
            />
            <div>
              {loading ? (
                <button type="button" className="btn btn-disabled">
                  {login
                    ? "O puedes crear una cuenta nueva"
                    : "Si ya tienes una cuenta, inicia sesión"}
                </button>
              ) : (
                <button
                  onClick={() => setLogin(!login)}
                  type="button"
                  className="btn btn-secondary"
                >
                  {login
                    ? "O puedes crear una cuenta nueva"
                    : "Si ya tienes una cuenta, inicia sesión"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
