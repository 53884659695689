import React from "react";
import { Outlet } from "react-router-dom";
import NavToolBar from "./NavToolbar";

const Dashboard = () => {
  return (
    <div className="flex bg-white">
      <NavToolBar />
      <div className="mr-2 mt-20 flex w-full flex-col gap-8 rounded-t-xl bg-blue-100 p-4 md:mx-4 ">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
