import React from 'react'
import Subtext from '../elements/Subtext'

const ProfileSkelleton = () => {
  return (
    <div
    className="mt-12 select-none flex flex-col items-center justify-evenly gap-8 md:flex-row md:items-start md:py-8"
  >
    {/* avatar div  */}
    <div className="flex flex-col items-center gap-4">
      <div className="avatar">
        <div className="w-52 rounded-full ring ring-primary ring-offset-2 ring-offset-base-100">
          <img
          className='skeleton'
            src=""
            alt=" "
          />
        </div>
      </div>
      <Subtext>Haz click en la imágen para cambiar tu foto de perfil</Subtext>
    </div>

    {/* name div  */}
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-2">
        <Subtext>Nombre(s):</Subtext>
        <input
          className="input skeleton input-disabled"
          type="text"
        />
      </div>
      <div className="flex flex-col gap-2">
        <Subtext>Apellido(s)</Subtext>
        <input
          className="input skeleton input-disabled"
        />
      </div>
      <button className="btn btn-disabled">Guardar</button>
    </div>
  </div>
  )
}

export default ProfileSkelleton
