import React, { lazy, Suspense } from "react";

const RegisterSkelleton = lazy(() => import("../skelletons/RegisterSkelleton"));
const LoginSkelleton = lazy(() => import("../skelletons/LoginSkelleton"));
const LoginForm = lazy(() => import("../components/LoginForm"));
const RegisterForm = lazy(() => import("../components/RegisterForm"));

const LandingRenderer = ({ login, loading, setLogin, setLoading }) => {
  const renderComponent = (Component) => (
    <Suspense fallback={<div>Loading...</div>}>
      <Component setLogin={setLogin} setLoading={setLoading}  />
    </Suspense>
  );

  if (loading && !login) {
    return renderComponent(RegisterSkelleton);
  } else if (loading && login) {
    return renderComponent(LoginSkelleton);
  } else if (!loading && login) {
    return renderComponent(LoginForm);
  } else if (!loading && !login) {
    return renderComponent(RegisterForm);
  } else {
    return null;
  }
};

export default LandingRenderer;
