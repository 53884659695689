import React, { createContext, useContext, useState } from "react";

const ReloadContext = createContext();

export const ReloadProvider = ({ children }) => {
  const [reload, setReload] = useState(false);
  const defaultAvatar =
    "https://www.digitalocean.com/_next/static/media/default-avatar.14b0d31d.jpeg";
  const defaultName = "Usuario";

  return (
    <ReloadContext.Provider value={{ reload, setReload, defaultAvatar,defaultName }}>
      {children}
    </ReloadContext.Provider>
  );
};

export const useReloadContext = () => {
  return useContext(ReloadContext);
};
