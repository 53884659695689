import React, { useEffect, useState, useContext } from "react";
import { auth, db } from "../firebase/FirebaseConfig";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

//se crea contexto de estado global
const AuthContext = React.createContext();

//hook para acceder al contexto (valor)
const useAuth = () => {
  return useContext(AuthContext);
};

//creacion de provider
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  //useState para saber cuando termina de comprobar la app si hay un usuario loggeado(onAuthStateChanged)
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [userData, setUserData] = useState(null);

  //Hook para  ejecutar la comprobacion una sola vez
  useEffect(() => {
    //comprobamos si hay usuario
    const cancelarSuscripcion = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoadingAuth(false);
    });
    return cancelarSuscripcion;
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const docRef = doc(db, "usuarios", user.uid);
        const userDoc = await getDoc(docRef);
        setUserData(userDoc.data());
      } catch (error) {
        console.log(error);
      }
    };

    if (!loadingAuth && user) fetchUserData();
  }, [loadingAuth,user]);

  return (
    <AuthContext.Provider value={{ user, userData }}>
      {/* solo se cargan los elementos una vez que se compruebe que existe un usuario */}
      {!loadingAuth  && children}
    </AuthContext.Provider>
  );
};

export { useAuth, AuthContext, AuthProvider };
