const handleFileChange = (e, setPreviewImage, setSelectedFile) => {
  const file = e.target.files[0];
  
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const maxImageSize = 200; // Set the maximum width or height for the profile photo

        let width = image.width;
        let height = image.height;

        if (width > height) {
          if (width > maxImageSize) {
            height *= maxImageSize / width;
            width = maxImageSize;
          }
        } else {
          if (height > maxImageSize) {
            width *= maxImageSize / height;
            height = maxImageSize;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);

        const resizedImage = canvas.toDataURL('image/jpeg'); // Use 'image/jpeg' for JPEG format

        setPreviewImage(resizedImage);
        setSelectedFile(file);
      };
    };

    reader.readAsDataURL(file);
  }
};

  
  export default handleFileChange;
  