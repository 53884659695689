import React from "react";
import { NavLink } from "react-router-dom";

const NavSidebaritems = ({ closeDrawer }) => {
  const sidebarOptions = [
    {
      title: "Panel",
      items: [
        {
          label: "Home",
          href: "",
        },
      ],
    },
    {
      title: "Menú",
      items: [
        {
          label: "Actualizar menú",
          href: "menu",
        },
      ],
    },
    {
      title: "Platillos destacados",
      items: [
        {
          label: "Barra fría",
          href: "barrafria",
        },
        {
          label: "Barra caliente",
          href: "barracaliente",
        },
        {
          label: "Entradas",
          href: "entradas",
        },
        {
          label: "Bebidas",
          href: "bebidas",
        },
      ],
    },
    {
      title: "Usuarios",
      items: [
        {
          label: "Administradores",
          href: "admin",
        },
        {
          label: "Capitán",
          href: "capitan",
        },
        {
          label: "Cocinera",
          href: "cocinera",
        },
        {
          label: "Empleado",
          href: "empleado",
        },
        {
          label: "Crear nuevo usuario",
          href: "nuevousuario",
        },
      ],
    },
    {
      title: "Tareas",
      items: [
        {
          label: "Lista 1",
          href: "lista1",
        },
        {
          label: "Lista 2",
          href: "lista2",
        },
        {
          label: "Lista 3",
          href: "lista3",
        },
      ],
    },
  ];

  return (
    <>
      {sidebarOptions.map((option, index) => (
        <div key={index} className="mb-4 select-none">
          <h2 className="mb-1 ml-4 text-violet-500">{option.title}</h2>
          <ul className="flex flex-col">
            {option.items.map((item, itemIndex) => (
              <NavLink
                key={itemIndex}
                className={({isActive}) =>
                  isActive
                    ? "mb-1 ml-8 w-fit rounded border-violet-700 bg-violet-700/70 px-3 py-1 text-white"
                    : "mb-1 ml-8 w-fit rounded px-3 py-1 text-violet-700 hover:border-violet-700 hover:bg-violet-700/70  hover:text-white"
                }
                to={`/${item.href}`}
                onClick={closeDrawer}
              >
                {item.label}
              </NavLink>
            ))}
          </ul>
        </div>
      ))}
    </>
  );
};

export default NavSidebaritems;
